import usersConfig, { moduleName as usersModuleName } from "./Users/config";
import applicationsConfig, {
    moduleName as applicationsModuleName,
} from "./Applications/config";

import IConfig from "interfaces/IConfig";
import { registerModule } from "moduleConfig";
import { registerProjects } from "rootProjects";
import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "Account";
export const url = `/account`;

const getConfig = (permissions: permissionsType) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    const routingChildrens = [];

    // register Users module
    const users = usersConfig(permissions, url);
    if (users.config.active) {
        registerModule(usersModuleName, users.config);
        routingChildrens.push(...users.routing);
        config.active = true;
    }

    // register Applications module
    const applications = applicationsConfig(permissions, url);
    if (applications.config.active) {
        registerModule(applicationsModuleName, applications.config);
        routingChildrens.push(...applications.routing);
        config.active = true;
    }

    if (routingChildrens.length > 0) {
        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="Account/index" />,
        });
    }

    if (config.active) {
        registerProjects([
            {
                id: moduleName,
                name: "Account",
                icon: "manage_accounts",
                url: `${url}`,
                priority: 200,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
