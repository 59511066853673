import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import { registerProjects } from "rootProjects";
import AsyncComponent from "ui/AsyncComponent";
import translateConfig, {
    moduleName as translateModuleName,
} from "./Translate/config";
import { registerModule } from "moduleConfig";
import IConfig from "interfaces/IConfig";

export const moduleName = "OnlineBooking";
export const url = `/online-booking`;

const getConfig = (permissions: permissionsType) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    const routingChildrens = [];
    //register Translate module

    const tr = translateConfig(permissions, url);
    if (tr.config.active) {
        config.active = true;
        registerModule(translateModuleName, tr.config);
        routingChildrens.push(...tr.routing);
    }

    if (routingChildrens.length) {
        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="OnlineBooking/index" />,
        });
    }

    if (config.active) {
        registerProjects([
            {
                id: moduleName,
                name: "Online Booking",
                icon: "language",
                url: `${url}`,
                priority: 200,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
