import { isProduction } from "helpers/isProduction";
import { url as moduleUrl } from "../config";
import ProjectContext from "app/Translate/context";
import IConfig from "interfaces/IConfig";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "OnlineBooking.translate";
export const url = "translate";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active:
            permissions["online_booking.translation"] &&
            permissions["translation.read"],
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (config.active) {
        routing.push({
            path: url,
            element: (
                <ProjectContext.Provider
                    value={{ project: "online_booking", url: moduleUrl }}
                >
                    <AsyncComponent path="Translate/pages/TranslateIndexPage" />
                </ProjectContext.Provider>
            ),
        });
        if (permissions["translation.compare.update"] && isProduction()) {
            routing.push({
                path: `${url}/compare`,
                element: (
                    <ProjectContext.Provider
                        value={{ project: "online_booking", url: moduleUrl }}
                    >
                        <AsyncComponent path="Translate/pages/TranslateComparePage" />
                    </ProjectContext.Provider>
                ),
            });
        }
    }

  

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
