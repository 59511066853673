import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import { registerProjects } from "rootProjects";
import AsyncComponent from "ui/AsyncComponent";
import translateConfig from "./Translate/config";
import { registerModule } from "moduleConfig";
import IConfig from "interfaces/IConfig";

export const moduleName = "Agent";
export const url = `/agent`;

const getConfig = (permissions: permissionsType) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    const routingChildrens = [];

    //register Translate module
    const tr = translateConfig(permissions, url);

    if (tr.config.active) {
        registerModule("agent.translation", tr.config);
        routingChildrens.push(...tr.routing);
        config.active = true;
    }

    if (routingChildrens.length > 0) {
        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="Agent/index" />,
        });
    }

    if (config.active) {
        registerProjects([
            {
                id: "agent",
                name: "Agent",
                icon: "account_circle",
                url: `${url}`,
                priority: 400,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
