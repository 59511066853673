import { Icon, MenuItem, Stack } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Select,
    Settings,
    SettingsItem,
} from "ui";
import { IGPFormRef } from "ui/Form/Form";
import { IBulkPushTranslations, ISelectionActionsModal } from "../interfaces";
import { translateBulkPush, translateCompareTableUpdate } from "../actions";

const TranslationsPushModal: FC<ISelectionActionsModal> = ({
    selected,
    onSuccess,
}) => {
    const { errors, isLoading, message, request, status } = useRequest();
    const formRef = useRef<IGPFormRef | null>();
    const dispatch = useDispatch();

    const handleCloseModal = () => dispatch(modalHide("TranslationsPushModal"));
    const handleStatus = () => {
        if (formRef.current !== null) handleSubmit(formRef?.current?.getData());
    };
    const handleSubmit = (data: any) => {
        const dataToSend: IBulkPushTranslations = {
            source: "dadmin",
            target: "admin",
            translation_map: selected.map((item: number) => ({
                id: item,
                languages: data.languages,
            })),
        };
        request(
            translateBulkPush(dataToSend, (response: AxiosResponse) => {
                if (response.status === 204) {
                    dispatch(modalHide("TranslationsPushModal"));
                    dispatch(translateCompareTableUpdate("selection", []));
                    onSuccess();
                }
            })
        );
    };

    const handleChangeLang = (
        { id, value }: { id: string; value: string[] },
        context: any
    ) => {
        const newVal = !value.includes("lang_en")
            ? ["lang_en", ...value]
            : value;
        context?.onChange({ id, value: newVal });
    };

    return (
        <Modal maxWidth="sm" fullWidth open>
            <ModalTitle onClose={handleCloseModal}>
                {trans("admin.translate.modal.translationsPushModal.title")}
            </ModalTitle>
            <ModalContent sx={{ display: "flex", flexDirection: "column" }}>
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{
                        mb: 1,
                    }}
                />
                <Form
                    ref={formRef}
                    data={{}}
                    errors={errors}
                    fields={{
                        languages: {
                            default: [
                                "lang_en",
                                "lang_nb",
                                "lang_nn",
                                "lang_sv",
                            ],
                        },
                    }}
                    loading={isLoading}
                    onSubmit={handleSubmit}
                    unsaved={false}
                >
                    <Settings>
                        <SettingsItem
                            label={trans(
                                "admin.translate.modal.translationsPushModal.languages"
                            )}
                        >
                            <Select
                                fullWidth
                                id="languages"
                                multiple
                                onChange={handleChangeLang}
                            >
                                {[
                                    "lang_en",
                                    "lang_nb",
                                    "lang_nn",
                                    "lang_sv",
                                ].map((item, idx) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                            key={`status-${item}-${idx}`}
                                            id={`status-${item}-${idx}`}
                                        >
                                            {trans(
                                                `admin.translate.modal.translationsPushModal.${item}`
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </SettingsItem>
                    </Settings>
                </Form>
            </ModalContent>
            <ModalActions
                sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        backgroundColor: "grey.200",
                        marginLeft: "0!important",
                        p: 1,
                        width: "100%",
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        fullWidth
                        onClick={handleStatus}
                        startIcon={<Icon>save</Icon>}
                    >
                        {trans("global.button.save")}
                    </LoadingButton>
                </Stack>
            </ModalActions>
        </Modal>
    );
};

export default TranslationsPushModal;
